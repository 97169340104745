import GoogleAuth0ProviderContext from "../../context/googleAuth0ProviderContext";

export const googleProviderConfig = {
  context: GoogleAuth0ProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: `${window.location.origin}?primary`,
    scope: "openid profile",
    connection: "google-oauth2",
  },
};
