import React from "react";
import useGetUsers from "../../hooks/orchestrate/user/useGetUsers";
import { Avatar, Space, Table } from "antd";

const Home = () => {
  const { data: users } = useGetUsers();
  console.log(users);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      key: "name",
      render: (record) => (
        <>
          <Space>
            <Avatar src={record.picture} />
            {record.name}
          </Space>
        </>
      ),
    },
  ];

  return (
    <Table pagination={false} columns={columns} dataSource={users || []} />
  );
};

export default Home;
