import React from "react";
import { Layout } from "antd";
import horizontalLogo from "../images/tldrparents-logo-horizontal.svg";
import { Link, Outlet, ScrollRestoration } from "react-router-dom";
import MaxWidthWrapper from "./maxWidthWrapper";

const { Header, Footer, Content } = Layout;

const headerStyle = {
  top: 0,
  zIndex: 998,
  padding: 0,
  width: "100%",
  height: 80,
  lineHeight: "64px",
  backgroundColor: "#ffffff",
  position: "sticky",
};

const contentStyle = {
  minHeight: 300,
  backgroundColor: "#fff",
};

const footerStyle = {
  color: "#ffffff",
  textAlign: "center",
  lineHeight: "30px",
  paddingBottom: "50px",
};

const LayoutChrome = () => {
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <ScrollRestoration />
      <Header style={headerStyle} /*className="breakpoint-indicator"*/>
        <MaxWidthWrapper maxWidth={1400}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img
                style={{
                  height: 80,
                  width: "auto",
                }}
                src={horizontalLogo}
                alt="TLDR Parents"
              />
            </Link>
          </div>
        </MaxWidthWrapper>
      </Header>
      <Content style={contentStyle}>
        <MaxWidthWrapper maxWidth={1400}>
          <Outlet />
        </MaxWidthWrapper>
      </Content>
      <Footer style={footerStyle}>
        <MaxWidthWrapper maxWidth={1400}></MaxWidthWrapper>
      </Footer>
    </Layout>
  );
};

export default LayoutChrome;
