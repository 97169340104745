import React, {useEffect} from "react";
import {Card} from "antd";
import googleSignInButton from "../../images/btn_google_signin_dark_normal_web@2x.png";
import logo from "../../images/tldrparents-logo-horizontal.svg";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation, useNavigate} from "react-router-dom";
import GoogleAuth0ProviderContext from "../../context/googleAuth0ProviderContext";

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0(
    GoogleAuth0ProviderContext,
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(
    function effectFunction() {
      if (isAuthenticated) {
        navigate("/");
      }
    },
    [isAuthenticated, navigate],
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ height: "10vh" }}></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: 7,
        }}
      >
        <div style={{ width: "100%", maxWidth: 500 }}>
          <Card styles={{ body: { textAlign: "center" } }}>
            <img src={logo} alt="TLDR Parents" />
            <img
              style={{ cursor: "pointer", maxWidth: 200 }}
              onClick={() => {
                const returnTo = location?.state?.from?.pathname;
                // TODO: Also include search params if required
                if (returnTo) {
                  return loginWithRedirect({
                    appState: { returnTo: returnTo },
                  });
                } else {
                  return loginWithRedirect();
                }
              }}
              src={googleSignInButton}
              alt="Sign In with Google"
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;
