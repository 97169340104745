import { createBrowserRouter } from "react-router-dom";
import Auth0ProviderWithRedirectCallbackOutlet from "./auth0ProviderWithRedirectCallbackOutlet";
import { googleProviderConfig } from "./auth0ProviderConfigs";
import Login from "./login";
import PrivateRoute from "./privateRoute";
import IndexComponent from "./indexComponent";
import NotFoundOrErrorPage from "../common/generic/notFoundOrErrorPage";
import Home from "../home/home";
import Logout from "./logout";
import LayoutChrome from "../layoutChrome";

const privateRoutes = [
  {
    element: <PrivateRoute />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
    ],
  },
];

const publicRoutes = [
  { index: true, element: <IndexComponent /> },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
];

const tldrRoutes = createBrowserRouter([
  {
    element: (
      <Auth0ProviderWithRedirectCallbackOutlet {...googleProviderConfig} />
    ),
    errorElement: <NotFoundOrErrorPage />,
    children: [
      {
        path: "/",
        element: <LayoutChrome />,
        children: [...publicRoutes, ...privateRoutes],
      },
    ],
  },
]);

export default tldrRoutes;
