import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import GoogleAuth0ProviderContext from "../../context/googleAuth0ProviderContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";

const InnerPrivateRoute = () => {
  const { data: currentUser } = useGetCurrentUser();

  function getJsx() {
    if (!currentUser) {
      return <AbsoluteSpin />;
    }

    if (currentUser.is_active === true && currentUser.is_admin) {
      return <Outlet />;
    } else {
      return <Navigate to="/logout" />;
    }
  }

  return getJsx();
};

const PrivateRoute = () => {
  const location = useLocation();
  const { isLoading: isAuth0Loading, isAuthenticated: isAuth0Authenticated } =
    useAuth0(GoogleAuth0ProviderContext);

  function getJsx() {
    if (isAuth0Loading) {
      return <AbsoluteSpin />;
    }

    if (isAuth0Authenticated) {
      return <InnerPrivateRoute />;
    }

    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{getJsx()}</>;
};

export default PrivateRoute;
