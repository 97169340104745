import useQueryGenericGet from "../../framework/useQueryGenericGet";
import {BACKEND_API_BASE_URL_ORCHESTRATE} from "../../../serviceUrls/baseUrls";
import {O_SERVICE_AUTH_CURRENT_USER_GET} from "../../../serviceUrls/serviceUrlsOrchestrate";

const useGetCurrentUser = () => {
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_AUTH_CURRENT_USER_GET,
    true,
    [],
    {},
    true,
  );
};

export default useGetCurrentUser;
