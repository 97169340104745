// ORCHESTRATE - Base Object Endpoints
// =====================================================================================================================

const O_AUTH = "auth";

// ORCHESTRATE - Auth
// =====================================================================================================================
export const O_SERVICE_AUTH_CURRENT_USER_GET = `/${O_AUTH}/current-user`;

// ORCHESTRATE - Auth
// =====================================================================================================================
export const O_SERVICE_ADMIN_USERS_GET_USERS = `/admin/users`;
