export const createQueryKey = (
  baseUrl,
  endpoint,
  extraPathParams,
  queryParamsOrPostDataDict,
) => {
  const baseUrlPart = baseUrl.split("/").filter((item) => {
    return item !== "";
  });

  const endPointPart = endpoint.split("/").filter((item) => {
    return item !== "";
  });

  let queryKey = [...baseUrlPart, ...endPointPart];

  if (extraPathParams && extraPathParams.length > 0) {
    queryKey = [...queryKey, ...extraPathParams];
  }

  if (queryParamsOrPostDataDict) {
    const queryParmsKeys = Object.keys(queryParamsOrPostDataDict).sort();
    for (const i in queryParmsKeys) {
      const key = queryParmsKeys[i];
      const value = queryParamsOrPostDataDict[key];
      queryKey.push(`${key}=${value}`);
    }
  }

  return queryKey;
};
