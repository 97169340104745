import useQueryGenericGet from "../../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import { O_SERVICE_ADMIN_USERS_GET_USERS } from "../../../serviceUrls/serviceUrlsOrchestrate";

const useGetUsers = () => {
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_ADMIN_USERS_GET_USERS,
    true,
    [],
    {},
    true,
  );
};

export default useGetUsers;
