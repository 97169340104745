import { useAuth0 } from "@auth0/auth0-react";
import GoogleAuth0ProviderContext from "../../context/googleAuth0ProviderContext";
import { useEffect } from "react";

const Logout = () => {
  const { logout } = useAuth0(GoogleAuth0ProviderContext);

  console.log(window.location.origin);
  useEffect(() => {
    logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: { returnTo: window.location.origin },
    }).then();
  }, []);

  return <></>;
};

export default Logout;
