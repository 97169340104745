import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {useAuth0} from "@auth0/auth0-react";
import GoogleAuth0ProviderContext from "../../context/googleAuth0ProviderContext";
import {getApiClientWithBaseUrl} from "./apiClient";
import {createQueryKey} from "../../utils/utils";

const useQueryGenericGet = (
  baseUrl,
  endpoint,
  sendAuthToken,
  extraPathParams,
  queryParams,
  enabled,
  staleTime,
) => {
  const { getAccessTokenSilently } = useAuth0(GoogleAuth0ProviderContext);

  if (!sendAuthToken) sendAuthToken = false;
  if (!extraPathParams) extraPathParams = [];
  if (!queryParams) queryParams = {};
  if (!staleTime) staleTime = 0;

  const queryKey = createQueryKey(
    baseUrl,
    endpoint,
    extraPathParams,
    queryParams,
  );

  return {
    ...useQuery({
      enabled: enabled !== false,
      queryKey: queryKey,
      queryFn: async () => {
        let token = undefined;
        if (sendAuthToken) {
          token = await getAccessTokenSilently();
        }

        const client = getApiClientWithBaseUrl(baseUrl, endpoint, token);
        return client.get(extraPathParams, queryParams);
      },
      placeholderData: keepPreviousData,
      staleTime: staleTime,
    }),
    queryKey: queryKey,
  };
};

export default useQueryGenericGet;
