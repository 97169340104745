import React from "react";
import {
  focusManager,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import tldrRoutes from "./components/routingAndAuth/tldrRoutes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { App as AntDApp, ConfigProvider } from "antd";

const queryClient = new QueryClient();

// I had to put this here because ReactQuery was not re-fetching data on focus after upgrading to v5.
focusManager.setEventListener((handleFocus) => {
  // Listen to visibilitychange and focus
  if (typeof window !== "undefined" && window.addEventListener) {
    window.addEventListener("visibilitychange", handleFocus, false);
    window.addEventListener("focus", handleFocus, false);
  }

  return () => {
    // Be sure to unsubscribe if a new handler is set
    window.removeEventListener("visibilitychange", handleFocus);
    window.removeEventListener("focus", handleFocus);
  };
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#168dff",
            colorBgLayout: "#168dff",
            colorLink: "#168dff",
            colorTextBase: "#2f2f2f",
            fontFamily: "Jost",
            fontWeightStrong: 700,
          },
          components: {
            Spin: {
              colorPrimary: "#ffffff",
              algorithm: true,
            },
            Button: {
              borderRadius: 20,
              borderRadiusLG: 28,
              borderRadiusSM: 8,
            },
          },
        }}
      >
        <AntDApp>
          <RouterProvider router={tldrRoutes} />
          <ReactQueryDevtools />
        </AntDApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
