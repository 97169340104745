import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import GoogleAuth0ProviderContext from "../../context/googleAuth0ProviderContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";

const IndexComponent = () => {
  let location = useLocation();
  const { isLoading: isAuth0Loading, isAuthenticated: isAuth0Authenticated } =
    useAuth0(GoogleAuth0ProviderContext);
  const { data: currentUser } = useGetCurrentUser();

  function getJsx() {
    if (isAuth0Loading) {
      console.log("Auth Loading");
      return <AbsoluteSpin />;
    }

    if (!isAuth0Authenticated) {
      console.log("Not authed, going to login");
      return <Navigate to="/login" state={{ from: location }} />;
    }

    if (!currentUser) {
      console.log("Waiting for current user to load");
      return <AbsoluteSpin />;
    }

    return <Navigate to={"/home"} />;
  }

  return <>{getJsx()}</>;
};

export default IndexComponent;
