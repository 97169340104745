import React from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallbackOutlet = ({ ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    if (appState) {
      if (appState.returnTo) {
        if (appState.returnTo.toLowerCase().startsWith("http")) {
          window.location.href = appState.returnTo;
          return;
        } else {
          navigate(appState.returnTo);
          return;
        }
      }
    }
    navigate("/");
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      <Outlet />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallbackOutlet;
